@import "styles/abstracts/index";
.animated {
  position: relative;
  overflow: hidden;
  .tile {
    transform: translateY(100%);
    transition: transform ease-in-out 0.4s, opacity ease-in-out 0.2s;
    position: absolute;
    inset: 0;
    opacity: 0;
    display: block;
  }
  .visible {
    transform: translateY(0);
    opacity: 1;
    transition: transform ease-in-out 0.4s, opacity ease-in-out 0.2s;
    position: relative;
  }
}

.lineContainer {
  position: relative;
  overflow: hidden;
  animation: underlineAnimation 3s linear infinite;
  border-radius: 20px;
  z-index: -1;

  .line {
    height: 4px;
    background-color: var(--yellow);
    width: 100%;
  }
}

@keyframes underlineAnimation {
  from {
    width: 0;
  }
  to {
    width: 80%;
  }
}
